.education {
    padding-bottom: 0rem;
    margin-bottom: 0;
}
.education__container {
    width: 100%;
    max-width: 900px; /* Adjust this value as needed */
    margin: 0 auto; /* Centers the container */
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    /* column-gap: 4rem; */ /* Remove or comment out this line */
}

.education__img{
    /* width: 350px; */
    border-radius: 1.5rem;
    justify-self: center;
    /* padding-top: 1rem; */
}

.education_info{
    grid-template-columns: repeate(3, 140px);
    gap: 0.5rem;
    margin-bottom: var(--mb-2);
}

.education__box{
    background-color: var(--container-bolor);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    /* text-align: left; */
    padding: 2rem 1.25rem;
}

.section__title{
    font-size: var(--h1-font-size);
    text-align: center;
    padding-bottom: 0.5rem;
}

.education__title{
    font-size: var(--h2-font-size);
    font-weight: var(--font-bold);
}
.education__topic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* font-family: 'Merriweather', serif; */
}
.education__date {
    /* padding-left: 5.5rem; Remove this */
    margin-left: auto;
    font-size: var(--h4-font-size);
    color: rgb(142, 114, 203);
    font-family: 'Merriweather', serif;
}
#uta{
    padding-left: 23rem;
}
.education__subtitle{
    margin-left: 2rem;
    color: rgb(73, 12, 205);
    font-size: var(--h4-font-size);
    font-weight: var(--font-medium);
    font-family: 'Merriweather', serif;
}
.inner__list{
    padding-left: 2rem;
}
.inner__list2{
    padding-left: 4rem;
}
.activities{
    font-weight: var(--font-semi-bold);
}
.education__subheading{
    font-size: var(--h4-font-size);
    font-weight: var(--font-bold);
    padding-top: 0.5rem;
    font-family:  'Abril Fatface';
}
.list__heading{
    font-family:  'Abril Fatface', cursive;
    color: rgb(48, 7, 136);
    font-weight: var(--font-semi-bold);
}
.education__description {
    padding: 0 4rem 0 0;
    margin-bottom: 0; /* Changed from var(--mb-2-5) to 0 */
    font-size: var(--h4-font-size);
    font-family: 'Merriweather', serif;
    color: black;
}



/* ========== BREAKPOINTS ========== */
/* For large devices */
@media screen and (max-width: 1092px){
    .education__container{
        grid-template-columns: 1fr;
        row-gap: 2.5rem;
    }

    .education__box{
        padding: 0.75rem 0.5rem;
    }
    .education__info{
        justify-content: center;
    }
    .education__description{
        padding: 0 5rem;
        margin-bottom: 2rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px){
    .education__info{
        grid-template-columns: 1fr;
    }
    .education__description{
        padding: 0;
    }
}

@media screen and (max-width: 576px){
}

/* For small devices */
@media screen and (max-width: 350px){
    .education__info{
        grid-template-columns: repeat(2, 1fr);
    }
}