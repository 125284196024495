.about__container{
    row-gap: 7rem;
}

.about__content{
    grid-template-columns: 50px repeat(2, 1fr);
    padding-top: 3rem;
    column-gap: 2rem;
    align-items: center;
    color: black;
}
.is__link{
    color:rgb(73, 12, 205);
}
.is__link:hover{
    color: rgb(101, 75, 152);
    font-weight: 610;
}
.about__social{
    margin-top: 4rem;
    display: grid;
    grid-template-columns: max-content;
    row-gap: 2rem;
}

.about__social-icon{
    font-size: 2rem;
    color: var(--title-color);
}

.about__social-icon:hover{
    color: var(--title-color-dark);
}
.about__title{
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}

.about__subtitle{
    position: relative;
    font-size: var(--h5-font-size);
    padding-right: 5.4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}

.about__description{
    font-size: var(--h4-font-size);
    font-weight: var(--font-bold);
    max-width: 500px;
    margin-bottom: var(--mb-1);
}
.about__img{
    background: url(../../assets/profile_pic.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    animation: profile__animate 8s ease-in-out infinite 1s;
}

@keyframes profile__animate{
    0%{
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
    50%{
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
    }
    100%{
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
}

.about__scroll{
    margin-left: 9.25rem;
}

.wheel{
    animation: scroll 2s ease infinite;
}

@keyframes scroll{
    0%{
        transform: translateY(0);
    }
    30%{
        transform: translateY(2rem);
    }
}

.about__scroll-name{
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
    margin-bottom: var(--mb-1);
}

.about__scroll-arrow{
    font-size: 1.25rem;
    color: var(--title-color);
    margin-bottom: var(--mb-1);
}

/* ========== BREAKPOINTS ========== */
/* For large devices */
@media screen and (max-width: 1992px){
    .about__content{
        grid-template-columns: 100px repeat(2, 1fr);
        column-gap: 1.25rem;
    }
    .about__subtitle{
        padding-left: 0.5rem;
        margin-bottom: var(--mb-1);
    }
    .about__subtitle::before{
        width: 42px;
        top: 0.8px;
    }
    .about__description{
        max-width: initial;
        margin-bottom: var(--mb-2);
    }
    .about__img{
        width: 300px;
        height: 300px;
        box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    }
    .about__scroll{
        margin-left: 7.5rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px){
    .about__content{
        grid-template-columns: 0.5fr 3fr;
        padding-top: 3.5rem;
    }

    .about__img{
        order: initial;
        justify-self: initial;
    }

    .about__data{
        grid-column: 1/3;
    }

    .about__img{
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
        width: 200px;
        height: 200px;
    }
    .about__scroll{
        display: none;
    }

    
}



/* For small devices */
@media screen and (max-width: 350px){
    .about__img{
        width: 180px;
        height: 180px;
    }
}