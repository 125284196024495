/* ========== GOOGLE FONDTS ========== */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* ========== VARIABLES ========== */
:root {
  --header-height: 3rem;

  /* ========== COLORS ========== */
  /* Color mode HSL (hue, saturation, lightness) */
  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 20%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --container-color:  #fff;

  /* ========== FONT AND TYPOGRAPHY ========== */
  --body-font: 'Poppins', sans-serif;

  /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px, 2rem = 32px */
  --big-font-size: 4rem;
  --h1-font-size: 3.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.2rem;
  --h4-font-size: 1.15rem;
  --h5-font-size: 1.1rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /* ========== Font weight ========== */
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 800;

  /* ========== Margenes Bottom ========== */
  /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px, 2rem = 32px */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /* ========== Z-INDEX ========== */
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* Responsive typography */
@media screen and (max-width: 992px){
    :root{
        --big-font-size: 2.75rem;
        --h1-font-size: 2rem;
        --h2-font-size: 1.25rem;
        --h3-font-size: 1rem;
        --normal-font-size: 0.938rem;
        --small-font-size: 0.813rem;
        --smaller-font-size: 0.75rem;
    }
}
/* ========== BASE ========== */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

body,
button,
input,
textarea {
    font-family: var(--body-font);
    font-weight: var(--normal-font-size);
}

body {
    background-color: var(--body-color);
    color: var(--text-color);
}

h1, h2, h3{
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
}

ul{
    list-style: none;
}

a {
    text-decoration: none;
}

button{
    cursor: pointer;
    border: none;
    outline: none;
}

img {
    max-width: 100%;
}
/* ========== REUSABLE CSS CLASSES ========== */
.section {
    padding: 6rem 0 2rem;
}

.section__title{
    font-size: var(--h1-font-size);
    color: var(--title-color);
}

.section__subtitle{
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: 4rem;
}

.section__title,
.section__subtitle{
    text-align: center;
}
/* ========== LAYOUT ========== */
.container {
    max-width: 968px;
    margin-left: auto;
    margin-right: auto;
}

.grid {
    display: grid;
    gap: 1.5rem;
}
/* ========== BUTTONS ========== */
.button {
    display: inline-block;
    background-color: var(--title-color);
    color: var(--container-color);
    font-weight: var(--font-medium);
    padding: 1.25rem 2rem;
    border-radius: 1rem;
}

.button:hover {
    background-color: var(--title-color-dark);
}

.button__icon{
    margin-left: var(--mb-0-5);
}

.button--flex {
    display: inline-flex;
    align-items: center;
}
/* ========== BREAKPOINTS ========== */
/* For large devices */
@media screen and (max-width: 1092px){
    .container {
        margin-left: var(--mb-1-5);
        margin-right: var(--mb-1-5);
    }
    .button{
        padding: 1rem 1.75rem;
    }
    .button__icon{
        width: 22px;
        height: 22px;
    }
}

/* For medium devices */
@media screen and (max-width: 768px){
    body{
        margin: 0 0 var(--header-height) 0;
    }
    .section{
        padding: 2rem 0 4rem;
    }
    .section__subtitle{
        margin-bottom: var(--mb-3);
    }
}

@media screen and (max-width: 576px){
}

/* For small devices */
@media screen and (max-width: 350px){
    /* :root{
        --big-font-size: 3.25rem;
    } */
    .container {
        margin-left: var(--mb-1);
        margin-right: var(--mb-1);
    }
    
}